.btn {
    display: inline-block;
    font-weight: 500;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    /* padding: .375rem .75rem; */
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 6px;
    padding-bottom: 4px;
    /* font-size: 13px; */
    /* background-color: #fff; */
    background-color: transparent;
    border: 0;
    /* min-height: 26px; */
    border-radius: 3px;
    box-shadow: var(--success) 0px 1px 1px 0px, var(--success) 0px 0px 0px 1px;
    /* border: 1px solid var(--success); */
    color: var(--success);
    font-weight: bold;
    margin-right: 1px;
    transition: all 0.2s;
}

.btn.btn_text {
    border: none;
    box-shadow: none;
}

.btn:hover {
    color: #fff;
    background-color: var(--success);
}

.btn_disabled {
    opacity: 0.6;
    pointer-events: none;
}

.icon {
    margin-left: 5px;
    margin-right: 5px;
}

.btn_primary {
    color: #fff;
    background: linear-gradient(to bottom, #4fc5fa 0%, #0f75f5 100%);
    border: 0;
    /* box-shadow:  0 1px 1px rgb(255 255 255 / 41%), 0px 2px 3px -2px rgb(0 0 0 / 30%); */
}

.btn_primary:focus {
    background: #0f75f5;
    box-shadow: 0 0 0 2px #64baff;
}

.btn_success:not(.btn_text) {
    color: #fff;
    /* background: linear-gradient(180deg, #32cd32 0, #3caa62); */
    background-color: var(--success);
    /* box-shadow: 0 1px 1px rgba(50, 205, 50, 0.12), 0 2px 3px -2px rgba(50, 205, 50, 0.32); */
    border: 0;
}

.btn_success:hover :global(.icon-active) {
    background: unset !important;
    color: #fff !important;
    -webkit-text-fill-color: unset !important;
}
.btn_danger :global(.icon-active) {
    background-color: var(--danger)!important;
    -webkit-text-fill-color: unset !important;
}

.btn_success:focus {
    box-shadow: 0 0 0 2px var(--success);
}

.btn_danger:not(.btn_text) {
    color: #fff;
    background-color: var(--danger);
    box-shadow: var(--danger) 0px 1px 1px 0px, var(--danger) 0px 0px 0px 1px;
    border: 0;
}

.btn_danger.btn_text {
    color: var(--danger);
}

.btn_danger:hover {
    background-color: var(--danger);
    color: #fff;
}

.btn_danger:focus {
    box-shadow: 0 0 0 2px var(--danger);
}