 
  .spinner {
    position: relative;
    display: inline-block;
    font-size: inherit;
    margin-left: 5px;
    margin-right: 5px;
    
  }
  .spinner_center {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .spinner_blade {
    position: absolute;
    left: 0.4629em;
    bottom: 0;
    width: 0.074em;
    height: 0.2777em;
    border-radius: 0.5em;
    background-color: transparent;
    transform-origin: center -0.2222em;
    -webkit-animation: spinner-fade 1s infinite linear;
            animation: spinner-fade 1s infinite linear;
  }
  .spinner_blade.black {
    position: absolute;
    left: 0.4629em;
    bottom: 0;
    width: 0.074em;
    /* height: 0.2777em; */
    height: 0.2777em;
    border-radius: 0.5em;
    background-color: transparent;
    transform-origin: center -0.2222em;
    -webkit-animation: spinner-fade-black 1s infinite linear;
            animation: spinner-fade-black 1s infinite linear;
  }
  .spinner_blade:nth-child(1) {
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    transform: rotate(0deg);
  }
  .spinner_blade:nth-child(2) {
    -webkit-animation-delay: 0.083s;
            animation-delay: 0.083s;
    transform: rotate(30deg);
  }
  .spinner_blade:nth-child(3) {
    -webkit-animation-delay: 0.166s;
            animation-delay: 0.166s;
    transform: rotate(60deg);
  }
  .spinner_blade:nth-child(4) {
    -webkit-animation-delay: 0.249s;
            animation-delay: 0.249s;
    transform: rotate(90deg);
  }
  .spinner_blade:nth-child(5) {
    -webkit-animation-delay: 0.332s;
            animation-delay: 0.332s;
    transform: rotate(120deg);
  }
  .spinner_blade:nth-child(6) {
    -webkit-animation-delay: 0.415s;
            animation-delay: 0.415s;
    transform: rotate(150deg);
  }
  .spinner_blade:nth-child(7) {
    -webkit-animation-delay: 0.498s;
            animation-delay: 0.498s;
    transform: rotate(180deg);
  }
  .spinner_blade:nth-child(8) {
    -webkit-animation-delay: 0.581s;
            animation-delay: 0.581s;
    transform: rotate(210deg);
  }
  .spinner_blade:nth-child(9) {
    -webkit-animation-delay: 0.664s;
            animation-delay: 0.664s;
    transform: rotate(240deg);
  }
  .spinner_blade:nth-child(10) {
    -webkit-animation-delay: 0.747s;
            animation-delay: 0.747s;
    transform: rotate(270deg);
  }
  .spinner_blade:nth-child(11) {
    -webkit-animation-delay: 0.83s;
            animation-delay: 0.83s;
    transform: rotate(300deg);
  }
  .spinner_blade:nth-child(12) {
    -webkit-animation-delay: 0.913s;
            animation-delay: 0.913s;
    transform: rotate(330deg);
  }
  
  @-webkit-keyframes spinner-fade {
    0% {
      background-color: #fff;
    }
    100% {
      background-color: transparent;
    }
  }
  
  @keyframes spinner-fade {
    0% {
      background-color: #fff;
    }
    100% {
      background-color: transparent;
    }
  }

  
  @-webkit-keyframes spinner-fade-black {
    0% {
      background-color: var(--success);
    }
    100% {
      background-color: transparent;
    }
  }
  
  @keyframes spinner-fade-black {
    0% {
      background-color: var(--success);
    }
    100% {
      background-color: transparent;
    }
  }
