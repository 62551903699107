.table-noborder-left>.dx-datagrid>.dx-datagrid-rowsview {
  border-left: 0px !important;
}

.table-noborder-left>.dx-datagrid>.dx-datagrid-headers {
  border-left: 0px !important;
}

.table-noborder-left>.dx-datagrid>.dx-datagrid-filter-panel {
  border-left: 0px !important;
}

.table-noborder-left .dx-datagrid-filter-panel {
  border-bottom: 0px !important;
}

/* 

.dx-datagrid-borders>.dx-datagrid-headers {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dx-datagrid-borders>.dx-datagrid-total-footer,
.dx-datagrid-borders>.dx-datagrid-rowsview {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}


.dx-datagrid-borders>.dx-datagrid-total-footer {
  border: 0px !important;
}

.dx-datagrid-headers .dx-datagrid-content {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.dx-datagrid-borders>.dx-datagrid-filter-panel {
  border: 0px !important;
  background-color: transparent !important;
} */

.dx-datagrid-filter-panel+.dx-pager {
  border: 0px !important;
  background-color: transparent !important;
  padding: 5px;
}

.dx-datagrid-rowsview .dx-row>td {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.dx-datagrid-headers .dx-row:not(.dx-header-row)>td {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.dx-datagrid-headers .dx-header-row>td {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  font-weight: bold !important;
}

.dx-editor-cell .dx-texteditor .dx-texteditor-input:not(.dx-texteditor-input-auto-resize):not(textarea) {
  height: 20px !important;
  line-height: 1.2rem !important;
}

/* .dx-editor-cell .dx-texteditor .dx-texteditor-input:not(textarea) {
  height: 20px !important;
  line-height: 1.2rem !important;
} */

.dx-datagrid .dx-editor-with-menu,
.dx-datagrid-container .dx-editor-with-menu {
  height: 20px !important;
}

.dx-datagrid .dx-menu .dx-menu-item .dx-menu-item-content,
.dx-datagrid-container .dx-menu .dx-menu-item .dx-menu-item-content {
  padding: 0px !important;
}

.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused)>td:not(.dx-focused) {
  background-color: rgba(0, 0, 0, .04);
}

.dx-datagrid {
  background-color: transparent !important;
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row>td.dx-cell-modified:not(.dx-field-item-content).dx-command-select,
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row>td.dx-command-select,
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row>td.dx-datagrid-invalid:not(.dx-field-item-content).dx-command-select {
  width: 36px !important;
  min-width: 36px !important;
  padding-left: 8px !important;
  padding-right: 10px !important;
}

.dx-checkbox-icon {
  border: 1px solid rgba(0, 0, 0, .2) !important;
  border-radius: 4px !important;
  width: 14px !important;
  height: 14px !important;
}

.dx-checkbox-checked .dx-checkbox-icon {
  /* color: var(--primary);
  background: linear-gradient(to bottom, #32CD32 0%, var(--primary) 100%); */
  color: black !important;
  background-color: #fff !important;

  border: none !important;
  font: 12px/12px DXIcons !important;
  text-align: center;
  box-shadow: none;
}

.dx-checkbox-checked .dx-checkbox-icon:before {
  margin-top: -6px !important;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>tr>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>tr>td {
  background: linear-gradient(to bottom, var(--primary) 0%, var(--primary) 100%);
  /* color: #fff !important; */
  /* border-left: 1px solid #FAFAFA!important;
      border-right: 1px solid #FAFAFA!important;
      border-bottom: 1px solid #FAFAFA!important;   */

}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row>td:not(.dx-focused),
.dx-datagrid-rowsview .dx-row-focused.dx-data-row>tr>td:not(.dx-focused) {
  background: linear-gradient(to bottom, var(--primary) 0%, var(--primary) 100%);
  color: black !important;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row>td {
  border-bottom: 1px solid var(--primary);
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row>td,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row>tr:last-child>td {
  border-bottom: 1px solid var(--primary);
}

.cmd-edit-icon {
  display: none;
  cursor: pointer;
}

.dx-state-hover .cmd-edit-icon {
  display: inline;
}

.cmd-edit-icon {
  color: var(--success);
}

.dx-row-focused .cmd-edit-icon {
  /* display: inline; */
  color: black;
}

.cmd-delete-icon {
  display: none;
  cursor: pointer;
}

.dx-state-hover .cmd-delete-icon {
  display: inline;
}

.cmd-delete-icon {
  color: var(--danger);
}

.dx-row-focused .cmd-delete-icon {
  /* display: inline; */
  color: black;
}

.dx-row-focused a {
  /* display: inline; */
  /* color: white !important; */
}

.dx-editor-cell.dx-editor-inline-block:not(.dx-command-select)::before {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row>td {
  border-bottom: 1px solid var(--primary) !important;

}

.dx-datagrid-rowsview .dx-row-focused.dx-group-row {
  background: linear-gradient(to bottom, var(--primary) 0%, var(--primary) 100%);
  /* color: rgba(0, 0, 0, .87) !important; */
}

.dx-datagrid-filter-row {
  background-color: transparent !important;
}

.dx-datagrid-filter-row input {
  padding-left: 20px !important;
}

.dx-datagrid-rowsview .dx-row.dx-group-row:not(.dx-row-focused) {
  background-color: transparent !important;
}

.dx-datagrid .dx-editor-with-menu .dx-menu-item-content .dx-icon.dx-icon-filter-operation-default,
.dx-datagrid-container .dx-editor-with-menu .dx-menu-item-content .dx-icon.dx-icon-filter-operation-default {
  margin-top: -2px !important;
  margin-right: 2px;
}

.table-white-space td {
  white-space: pre-line;
}

.dx-datagrid-filter-panel .dx-datagrid-filter-panel-clear-filter,
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-text {
  color: var(--primary) !important;
}

.dx-datagrid-filter-panel .dx-icon-filter {
  color: var(--primary) !important;
}

.dx-datagrid .dx-header-filter:not(.dx-header-filter-empty),
.dx-datagrid-container .dx-header-filter:not(.dx-header-filter-empty) {
  color: var(--primary) !important;
}

.dx-checkbox-indeterminate .dx-checkbox-icon {
  background-color: var(--primary) !important;
}

.dx-pager .dx-page-sizes .dx-selection,
.dx-pager .dx-pages .dx-selection {
  background-color: var(--primary) !important;
}


.dx-datagrid .dx-row>td {
  padding: 5px 6px !important;
  font-size: 12px !important;
  font-weight: 400;
}

.dx-datagrid-header-panel {
  background-color: transparent !important;
}

.dx-datagrid-header-panel .dx-toolbar {
  background-color: transparent !important;
}

.dx-treelist .dx-row>td {
  padding: 5px 6px !important;
  font-size: 12px !important;
  font-weight: 500;

}

td .dx-texteditor.dx-editor-outlined .dx-placeholder:before {
  padding: unset !important;
}

td[role=columnheader] {
  text-align: center !important
}

.main_column {
  font-weight: 600 !important;
}
.cell_editor_text_area{
  padding: 0!important;
}
.cell_editor_text_area textarea{
  padding-left: 0px!important;
}

.dx-datagrid .dx-row>td:not(.cell_editor_text_area){
  padding: 5px 6px !important;
  font-size: 12px !important;
  font-weight: 400;
}
