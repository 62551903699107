.dx-popup-wrapper > .dx-overlay-content {
    border-radius: 0.8rem!important;
    background: rgba(255,255,255,0.68)!important;
    backdrop-filter: blur(20px);
    
}
.dx-popup-content{
    padding-left: 0px!important;
    padding-right: 0px!important;
}
.dx-overlay-shader{
    background-color: rgba(0,0,0,0)!important;
}
.dx-popup-title.dx-toolbar .dx-toolbar-items-container{
    height: 30px!important;
}
.dx-popup-title.dx-toolbar {
    display: flex;
    align-items: center;
    min-height: 38px;
}
.pop-up-actions{
    border-top: 1px solid #e0e0e0;
    padding-top: 1rem;
    text-align: center;
    margin-top: 1rem;
    padding-bottom: 2px;
}
.pop-up-actions button{
    min-width: 100px;
}
.pop-up-header{
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 1rem;
    text-align: left;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}
.pop-up-header-title{
    font-size: 1.2rem;
    font-weight: bold;
    flex-grow: 1;
}
.pop-up-header-actions{
    float: right;
}
.pop_up_no_padding .dx-popup-content{
    padding: 0!important;
}