body {
  margin: 0;
  font-family: -apple-system, "Inter", sans-serif !important;
  /* font-family: 'Open Sans', sans-serif !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

:root {
  /* --primary: #0f75f5; */
  --primary: #CBCCFF;
  --success: #5B63B7;
  --danger: #ED5E93;
  --bg-main-container: rgba(60, 170, 101, 0.08);
}

.text-danger {
  color: #ED5E93;
}

.dx-overlay-wrapper {
  font-family: -apple-system, "Inter", sans-serif !important;
  /* font-family: 'Open Sans', sans-serif !important; */
}

.dx-widget input,
.dx-widget textarea {
  font-family: -apple-system, "Inter", sans-serif !important;
  /* font-family: 'Open Sans', sans-serif !important; */
}

.dx-widget {
  font-family: -apple-system, "Inter", sans-serif !important;
  /* font-family: 'Open Sans', sans-serif !important; */
  font-size: 12px !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: -apple-system, "Inter", sans-serif !important;
  /* font-family: 'Open Sans', sans-serif !important; */
  line-height: 1.28571429em;
  margin: calc(2rem - .14285714em) 0 1rem;
  font-weight: 700;
  padding: 0;
}

.body-container .dx-drawer-content {
  background-color: var(--bg-main-container);
}

.hidden {
  display: none;
}

.pop-up-form {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;

}

.my-animation-popup .dx-popup-title .dx-toolbar-before {
  width: 100%;
}

.dx-accordion-item-title {
  height: auto !important;
}

.dx-accordion-item {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
}

.page-toolbar {
  padding-top: 5px !important;
  padding-left: 20px !important;
  padding-right: 10px !important;
  padding-bottom: 5px !important;
}

.dx-toolbar {
  background-color: transparent !important;
}

.app {
  /* background:linear-gradient(45deg, #EEEEEE, #FAF8F9, #FFFFFF); */
  /* background-color: #F8F8FF; */
}

.dx-selectbox-container input {
  color: var(--success);
  font-weight: bold;
}

.dx-texteditor.dx-editor-filled.dx-state-focused {
  border-color: var(--primary) !important;
  box-shadow: 0 0 0 2px var(--primary) !important;
}

.dx-button-mode-text .dx-icon {
  color: var(--success) !important;
}

.dx-list .dx-empty-message,
.dx-list-item {
  color: var(--success) !important;
  ;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused {
  background-color: rgba(0, 0, 0, .04);
  color: var(--success) !important;
}

.dx-list-item .dx-icon {
  color: var(--success) !important;

}

.dx-editor-cell .dx-texteditor .dx-texteditor-input {
  line-height: 1.3rem !important;
}

.column_wrap {
  white-space: pre-wrap;
}

.icon {
  background: linear-gradient(-135deg, #838487, #3c3e43);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.icon-active {
  background: linear-gradient(-135deg, var(--success), #94A0FF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dx-overlay-content .dx-icon {
  background: linear-gradient(-135deg, var(--success), #94A0FF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dx-overlay-content .dx-icon-clear {
  background: unset!important;
  -webkit-background-clip: unset!important;
  -webkit-text-fill-color: unset!important;
}

button:hover .icon {
  background: unset;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
}

/* .my-dropdown-button .dx-overlay-content {
  background-color: var(--primary) !important;
} */


.limit1Line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.limit2Line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.limit3Line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.box-image {
  .dx-list-item {
      width: 50% ;
      float: left;
      /* height: 100px; */
      /* width: auto; */
  }
}