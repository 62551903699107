
  .load_line{
    position: relative;
    height: 1rem;
    width: 100%;
    background-color:rgba(0,0,0,0.1);
    z-index: 44;
    overflow: hidden;
    border-radius: 2px;
    margin-bottom: 0.8rem;
  }
  .activity{
    position: absolute;
    left: -45%;
    height: 100%;
    width: 45%;
    background-image: linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
    background-image: -moz-linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
    background-image: -webkit-linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
    animation: loading 1s infinite;
    z-index: 45;
  }
  
  @keyframes loading {
    0%{
      left: -45%;
    }
    100%{
      left: 100%;
    }
  }