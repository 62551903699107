.form_group {
    text-align: left;
    margin-bottom: 1rem;
}

.form_group_label {
    margin-top: 0px;
    margin-bottom: 0.5rem;
}

.form_group_inline {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}
.form_group_inline.border{
    border-bottom: 1px solid #cecece;
}

.form_group_inline_label {
    margin-right: 10px;
}

.form_group_helptext {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.9rem;
    margin-top: 0.5rem;
    margin-bottom: 0rem;
    white-space: pre-line;
}

.form_group_inline.fluid label {
    flex: 1;
}
.lable_bold{
    font-weight: bold;
}