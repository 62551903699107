.form {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    /* padding-bottom: 10px; */
}

.actions {
    border-top: 1px solid #e0e0e0;
    padding-top: 20px;
    text-align: center;
    margin-top: 10px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 2px;
}

.actions button {
    min-width: 100px;
    margin-left: 2px;
    margin-right: 2px;
}