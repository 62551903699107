.filter{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.filter .left{
    display: flex;
    flex: 1;
}
.filter .item{
    margin-right: 10px;
}