.dx-rtl .dx-texteditor.dx-editor-filled.dx-show-clear-button .dx-texteditor-input, .dx-rtl .dx-texteditor.dx-editor-outlined.dx-show-clear-button .dx-texteditor-input, .dx-rtl.dx-texteditor.dx-editor-filled.dx-show-clear-button .dx-texteditor-input, .dx-rtl.dx-texteditor.dx-editor-outlined.dx-show-clear-button .dx-texteditor-input, .dx-texteditor.dx-editor-filled .dx-placeholder:before, .dx-texteditor.dx-editor-filled .dx-texteditor-input, .dx-texteditor.dx-editor-outlined .dx-placeholder:before, .dx-texteditor.dx-editor-outlined .dx-texteditor-input {
  /* padding: 8px 8px 7px!important; */
}
.dx-texteditor.dx-editor-outlined{
  border-radius: 5px!important;
}

.dx-texteditor.dx-editor-outlined.dx-state-hover{
  box-shadow: 0 0 0 2px var(--primary)!important;
  /* box-shadow: 0 0 0 2px rgb(13 110 253 / 25%)!important; */
}
.dx-texteditor.dx-editor-outlined.dx-state-focused {
  box-shadow:  0 0 0 2px var(--success)!important;
  /* box-shadow: inset 0 0 0 2px #3caa62!important; */
}
.dx-texteditor.dx-state-active::before, .dx-texteditor.dx-state-focused::before{
  border-bottom: 0!important;
}
.dx-texteditor.dx-editor-filled{
  border-radius: 5px!important;
 
}
.dx-texteditor.dx-editor-filled::after{
  border-bottom: 0!important;
}
.dx-texteditor.dx-editor-filled.dx-state-focused{
  border-color: #86b7fe;
    box-shadow: 0 0 0 2px rgb(13 110 253 / 40%)!important;
}
.dx-texteditor.dx-editor-filled.dx-state-hover{
  box-shadow: 0 0 0 2px rgb(13 110 253 / 25%)!important;
}
.dx-datagrid-filter-row.dx-editor-filled.dx-state-focused {
  border-color: #86b7fe;
  box-shadow: 0 0 0 2px rgb(13 110 253 / 40%)!important;
}
.dx-datagrid-filter-row .dx-texteditor.dx-state-hover{
  box-shadow: none!important;
}
.dx-datagrid-filter-row .dx-texteditor.dx-state-focused{
  box-shadow: none!important;
}
.dx-editor-cell.dx-focused .dx-texteditor{
  box-shadow: none!important;
}
.dx-switch-on-value .dx-switch-handle::before{
  background-color: var(--success)!important;
}
.dx-switch-on-value .dx-switch-container::before {
  background-color: var(--primary)!important;
}