.caption {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    font-weight: bold;

}

.caption p {
    font-size: 30px;
    margin-bottom: 10px;
}

.chart {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
}

.main {
    flex: 1;
    /* padding: 15px; */
    /* margin-top: 5px; */
    padding: 10px;

    border-radius: 10px;
    width: 50%;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; */
    /* box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px; */

}

.main>div {
    height: 450px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.extends {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 50%;

}

.khoiChart {
    width: 50%;
    padding: 10px;
}

.khoiChart>div {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    border-radius: 10px;
    /* margin-bottom: 20px; */
    height: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.actions {
    width: 100%;
    text-align: right;
    padding-right: 10px;
    padding-bottom: 10px;
}